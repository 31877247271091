// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import App from './app.js'
window.App = App

import Alpine from './src/alpine.js'
window.Alpine = Alpine

import { directive } from 'livewire-rails'
Alpine.plugin(directive)

import './src/components/account_select.js'
import './src/components/datepicker.js'
import './src/components/position_filter.js'
import './src/components/journal_chart.js'

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start()
  App.init()
})
