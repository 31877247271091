import consumer from "./channels/consumer.js"
import { replaySubscription } from "./channels/replay_subscription.js"
import { streamingSubscription } from "./channels/streaming_subscription.js"

export default {
  streamer: consumer,
  replaySubscription,
  streamingSubscription,

  log (msg) {
    console.log(msg)
  },

  document () {
    return document
  },

  visit (location) {
    window.location.href = location
  },

  hideRow (id) {
    let hidden = JSON.parse(localStorage.getItem('hiddenRows') || '[]')
    hidden.push(id)
    localStorage.setItem('hiddenRows', JSON.stringify(hidden))
    document.getElementById(id).classList.add('hidden')

    this.updateProfit()
  },

  unhideRows () {
    let hidden = JSON.parse(localStorage.getItem('hiddenRows') || '[]')
    localStorage.setItem('hiddenRows', '[]')

    hidden.map((id) => {
      document.getElementById(id).classList.remove('hidden')
    })

    this.updateProfit()
  },

  updateProfit () {
    try {

      let profit = Array.from(document.querySelectorAll('[data-profit]'))
        .map((node) => node.classList.contains('hidden') ? 0 : parseFloat(node.dataset.profit) )
        .reduce((prev, curr) => { return prev + curr })
        .toFixed(2)

      let el = document.getElementById('totalProfit')
      el.innerText = `$${profit}`

      if (profit >= 0) {
        el.classList.add('text-green-500')
        el.classList.remove('text-red-500')
      } else {
        el.classList.add('text-red-500')
        el.classList.remove('text-green-500')
      }
    } catch {}
  },

  init () {
    let hidden = JSON.parse(localStorage.getItem('hiddenRows') || '[]')
    hidden.map((id) => {
      document.getElementById(id)?.classList?.add('hidden')
    })

   this.updateProfit()
  }
}
