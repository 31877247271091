import Alpine from '../alpine.js'

Alpine.data('positionFilter', ({ trade_type, state, symbol, date }) => ({
  trade_type: trade_type || '',
  state: state || '',
  symbol: symbol || '',
  date: date || null,
  showModal: false,
  applyFilters () {
    window.location.href = `${window.location.href.split('?')[0]}?trade_type=${this.trade_type}&state=${this.state}&symbol=${this.symbol}` + (this.date ? `&date=${this.date}` : '')
  }
}))
