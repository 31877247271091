import Alpine from '../alpine.js'

Alpine.data('datepicker', ({ label, initialDate }) => ({
  label: label,
  initialDate: initialDate,
  showDatepicker: false,
  datepickerValue: '',
  month: '',
  year: '',
  no_of_days: [],
  blankdays: [],
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

  init () {
    this.initDate()
    this.getDaysOfMonth()
  },

  initDate() {
    if (this.initialDate) {
      let dateParts = this.initialDate.split(/\D/).map(s => parseInt(s))
      dateParts[1] = dateParts[1] - 1 // adjust month
      let day =  new Date(...dateParts)
      this.month = day.getMonth()
      this.year = day.getFullYear()
      this.datepickerValue = day.toDateString()
    } else {
      let today = new Date()
      this.month = today.getMonth()
      this.year = today.getFullYear()
      this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString()
    }
  },

  isToday (day) {
    const today = new Date()
    const d = new Date(this.year, this.month, day)

    return today.toDateString() === d.toDateString()
  },

  isChosen (day) {
    let selectedDate = new Date(this.year, this.month, day)

    return this.datepickerValue === selectedDate.toDateString()
  },

  chooseDate (day) {
    let selectedDate = new Date(this.year, this.month, day)
    this.datepickerValue = selectedDate.toDateString()

    let dateString = selectedDate.getFullYear() +"-"+ ('0'+ (selectedDate.getMonth() + 1)).slice(-2) +"-"+ ('0' + selectedDate.getDate()).slice(-2)

    this.showDatepicker = false
    console.log(this.$refs.date)

    this.$dispatch('change', { date: dateString })
  },

  getDaysOfMonth () {
    let daysInMonth = new Date(this.year, this.month + 1, 0).getDate()

    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay()
    let blankdaysArray = []
    for ( var i=1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i)
    }

    let daysArray = []
    for ( var i=1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }

    this.blankdays = blankdaysArray
    this.daysOfMonth = daysArray
  }
}))
