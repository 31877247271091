import Alpine from '../alpine.js'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'

Alpine.data('journalChart', ({ data }) => ({
  data,
  init() {
    let chart = new Chart(this.$refs.journalCanvas.getContext('2d'), {
      type: 'line',
      data: {
        datasets: [{
          data: this.data,
          backgroundColor: 'rgb(74, 222, 128)',
          borderColor: 'rgb(74, 222, 128)'
        }]
      },
      options: {
        interaction: { intersect: false },
        scales: {
          y: { beginAtZero: false },
          x: {
            type: 'time',
            time: {
              unit: 'day'
            }
          }
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            displayColors: false,
            callbacks: {
              label(point) {
                console.log({point})
                return `$${point.raw.y}`
              }
            }
          }
        }
      }
    })
  }
}))
