import consumer from "./consumer.js"

const replaySubscription = () => {
  return {
    subscription: null,
    ladderRendered: false,
    priceNodes: {},
    prevDomPrices: [],
    quote: {},
    connect (filename) {
      this.subscription = consumer.subscriptions.create({ channel: "ReplayChannel", filename }, {
        // Called once when the subscription is created.
        initialized: () => {
          console.log("initialized")
          this.bidNode = document.getElementById("bid")
          this.askNode = document.getElementById("ask")
          this.lastNode = document.getElementById("last")
          this.tasNode = document.getElementById("tas")
          this.ordersNode = document.getElementById("orders")
        },

        // Called when the subscription is ready for use on the server.
        connected: () => {
          console.log("connected")
        },

        // Called when the WebSocket connection is closed.
        disconnected: () => {
          console.log("disconnected")
        },

        // Called when the subscription is rejected by the server.
        rejected: () => {
          console.log("rejected")
        },

        received: (data) => {
          console.log(data)
          switch(data.name)
          {
            case "quote":
              if (!this.ladderRendered) { this.renderLadder(data) }

              try {

              if (data.bid) {
                this.bidNode.innerHTML = data.bid

                if (this.quote.bid) {
                  let prevBidCents = Math.round(this.quote.bid * 100)
                  this.priceNodes[prevBidCents].querySelector(`#bid-${prevBidCents}`).innerHTML = ''
                }

                let bidCents = Math.round(data.bid * 100)
                console.log({ bidCents, priceNodes: this.priceNodes, selector: `#bid-${bidCents}` })
                this.priceNodes[bidCents].querySelector(`#bid-${bidCents}`).innerHTML = 'B'
                this.quote.bid = data.bid
              }
              if (data.ask) {
                this.askNode.innerHTML = data.ask

                if (this.quote.ask) {
                  let prevAskCents = Math.round(this.quote.ask * 100)
                  this.priceNodes[prevAskCents].querySelector(`#ask-${prevAskCents}`).innerHTML = ''
                }

                let askCents = Math.round(data.ask * 100)
                this.priceNodes[askCents].querySelector(`#ask-${askCents}`).innerHTML = 'A'
                this.quote.ask = data.ask
              }
              if (data.last) {
                this.lastNode.innerHTML = data.last
                this.quote.last = data.last
              }

              } catch (e) {
                console.log(e)
              }
              break;
            case "timesale_equity":
              this.tasNode.insertAdjacentHTML('afterbegin',`
                <div class="w-full flex items-center text-sm ${ data.price >= this.quote.ask ? 'text-green-700' : (data.price <= this.quote.bid ? 'text-red-800' : '')}">
                  <span class="w-full text-right">${data.size}</span>
                  <span class="mx-2">-</span>
                  <span class="w-full">$${data.price}</span
                </div>
              `)
              break;
            case "timesale_futures":
              this.tasNode.insertAdjacentHTML('afterbegin',`
                <div class="w-full flex items-center text-sm ${ data.price >= this.quote.bid ? 'text-green-700' : '' }">
                  <span class="w-full text-right">${data.size}</span>
                  <span class="mx-2">-</span>
                  <span class="w-full">$${data.price}</span
                </div>
              `)
              break;
            case "dom":
              for(var bid in data.bids) {
                if (data.bids[bid] !== 0 || !data.asks[bid]) {
                  let bidCents = Math.round(bid * 100)
                  let priceNode = this.priceNodes[bidCents]
                  if (priceNode) {
                    this.renderPriceNode(priceNode, data.bids[bid], data.max, bid, bidCents, 'bid')
                  }
                }
              }

              for(var ask in data.asks) {
                if (data.asks[ask] !== 0 || !data.bids[ask]) {
                  let askCents = Math.round(ask * 100)
                  let priceNode = this.priceNodes[askCents]
                  if (priceNode) {
                    this.renderPriceNode(priceNode, data.asks[ask], data.max, ask, askCents, 'ask')
                    // priceNode.innerHTML = `
                    //   <span class="absolute top-0 right-0 left-0 -z-10 text-xs">${data.asks[asks[i]]}</span>
                    //   <span class="bg-red-300 absolute bottom-0 right-0 left-0 -z-10" style="height: ${data.asks[asks[i]]/data.max * 100}%;"></span>
                    //   <span class="text-xs ${(asks[i] === this.quote.ask.toString()) ? 'text-red-800' : ''} ">${asks[i]}</span>
                    // `
                  }
                }
              }
              break;
            case "order_activation":
            case "order_cancel_request":
            case "order_cancel_replace_request":
            case "order_entry_request":
            case "order_fill":
            case "order_route":
            case "order_rejection":
            case "order_partial_fill":
            case "too_late_to_cancel":
            case "urout":
              console.log('hit')
              let orderNode = this.ordersNode.querySelector(`#order-${data.order_key}`)
              if (orderNode) {
                orderNode.innerHTML = `
                  <div class="">
                    <span class="text-gray-500">${data.order_key}</span>
                    <span class="">
                      <span class="${data.side === "Buy" ? 'text-green-600' : 'text-red-600'} mx-1 text-base">${data.side}</span>
                      <span class="">${data.order_quantity}@${data.order_limit || "market"} | ${data.name}</span>
                    </span>
                  </div>
                  <div class="border-l-2 border-gray-300 ${data.execution_quantity || "invisible"}">
                    <span class="">${data.execution_quantity}@${data.execution_price}</span>
                  </div>
                `
              } else {
                this.ordersNode.insertAdjacentHTML('afterbegin',`
                  <div id="order-${data.order_key}" class="w-full flex-col items-start text-sm text-blue-700'">
                    <div class="">
                      <span class="text-gray-500">${data.order_key}</span>
                      <span class="">
                        <span class="${data.side === "Buy" ? 'text-green-600' : 'text-red-600'} mx-1 text-base">${data.side}</span>
                        <span class="">${data.order_quantity}@${data.order_limit || "market"} | ${data.name}</span>
                      </span>
                    </div>
                    <div class="border-l-2 border-gray-300 ${data.execution_quantity || "invisible"}">
                      <span class="">${data.execution_quantity}@${data.execution_price}</span>
                    </div>
                  </div>
                `)
               }
              break;
            case "finished":
              alert("Streaming file has finished.")
            default:
              console.log("ignoring data")
              console.log({ data })
              //default statement or expression;
          }
        }
      })

      return this.subscription
    },

    perform (action, params={}) {
      return this.subscription.perform(action, params)
    },

    disconnect () {
      console.log("disconnected")
      return this.subscription.unsubscribe()
    },

    // need to work in cents here b/c maths so a tick of 1 is one cent
    renderLadder (quote, tick = 1) {
      console.log("RENDERING LADDER")
      const ladderNode = document.getElementById("ladder")

      let currentPriceCents = Math.max(0, Math.round(quote.bid * 100) - (100 * tick))
      let finalPriceCents = Math.round(quote.ask * 100) + (100 * tick)

      while (currentPriceCents <= finalPriceCents) {
        const node = document.createElement("div")
        node.setAttribute("class", "p-1 w-12 border border-gray-300 h-full text-center flex items-end justify-center relative z-10 overflow-hidden flex-shrink-0")
        node.dataset.price = `${currentPriceCents}`
        this.renderPriceNode(node, 0, 1, (currentPriceCents/100.0).toFixed(2), currentPriceCents, null)
        // node.innerHTML = `
        //   <span class="absolute top-0 right-0 left-0 -z-10 text-xs"></span>
        //   <span class="bg-green-300 absolute bottom-0 right-0 left-0 -z-10" style="height: 0%;"></span>
        //   <span class="text-xs">${(currentPriceCents/100.0).toFixed(2)}</span>
        // `
        // store this node in a hash to reference this faster later
        this.priceNodes[currentPriceCents] = node
        ladderNode.appendChild(node)

        currentPriceCents = currentPriceCents + tick
      }

      this.ladderRendered = true
      ladderNode.scrollLeft = parseInt(ladderNode.scrollWidth/2 - ladderNode.offsetWidth/2)
    },

    renderPriceNode (priceNode, size, max, price, priceCents, side) {
      let atBid = this.isBid(price)
      let atAsk = this.isAsk(price)

      priceNode.innerHTML = `
        <span id="size-${priceCents}" class="absolute top-0 right-0 left-0 z-10 text-xs">${size}</span>
        <span id="height-${priceCents}" class=" ${ side === 'ask' ? 'bg-red-300' : (side === 'bid' ? 'bg-green-300' : '') } absolute bottom-0 right-0 left-0 -z-10" style="height: ${size/max * 100}%;"></span>
        <span id="price-${priceCents}" class="text-xs">${price}</span>
        <span id="bid-${priceCents}" class="text-red-900 absolute bottom-4">${atBid ? 'B' : ''}</span>
        <span id="ask-${priceCents}" class="text-green-900 absolute bottom-4">${atAsk ? 'A' : ''}</span>
      `
    },

    isBid (price) {
      if (this.quote && this.quote.bid) {
        return price === this.quote.bid.toString()
      }
    },

    isAsk (price) {
      if (this.quote && this.quote.ask) {
        return price === this.quote.ask.toString()
      }
    }
  }
}

export { replaySubscription }
